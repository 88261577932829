
<template>
	  <div class="hello">
		<div style="position: absolute;top:0;margin-top: 10px;border:0px solid red;">
			<span>请选择模板: </span>
			<select v-model="selected" @change="selectExcel" style="width: 200px;height: 30px;">
			  <option disabled value="">Choose</option>
			  <option v-for="option in options" :key="option.text" :value="option.value">
				{{ option.text }}
			  </option>
			</select><br/><br/>
			<div style="width: 65%;float: left;">
				<input style="font-size:16px;" type="file" id="fileupload" accept=".xlsx,.xls" @change="uploadExcel" />
			</div>
			<div style="width: 30%;float: right;"> 
				<a href="javascript:void(0)" @click="downloadExcel">
						<button style="font-size:16px;">导出excel</button>  
				</a>
			</div>
		</div>
		<div class="pop" v-show="isflag">
		　　<img src="../assets/notice@2x.png" alt="" style='width:50%; margin-left: 35%;'>
		</div>
		<div id="luckysheet"
		  style="margin:0px;padding:0px;position:absolute;width:100%;left: 0px;top: 100px;bottom:0px;"></div>
		<div v-if="isflag && isMaskShow" style="position: absolute;z-index: 1000000;left: 0px;top: 0px;bottom: 0px;right: 0px; background: rgba(255, 255, 255, 0.8); text-align: center;font-size: 40px;align-items:center;justify-content: center;display:flex;">
			Downloading
		</div>
		<div v-else></div>
		
	  </div>
</template>

<script>
import LuckyExcel from 'luckyexcel';
// 引入依赖包
import xlsx from 'xlsx'; 
// 代码见下
import { exportExcel } from './export.js'; 
export default {
  name: '蓝牙卡尺', 
  props: {
    msg: String
  },
  data(){
    return {
	  excelname:'',
      selected:"",
      options: [
		{ text: 'Template.xlsx',  value: 'https://img.jerayhealth.com/Template.xlsx' },
        { text: 'Template1.xlsx', value: 'https://img.jerayhealth.com/Template1.xlsx' },
      ],
      isMaskShow: false,
	  isflag: false,
    } 
  }, 
  mounted() {
		this.isWeChatBrowser();
		
          $(function () {
            luckysheet.create({
              container: 'luckysheet', // 设定DOM容器的id
              title: 'Luckysheet Demo', // 设定表格名称
              lang: 'zh', // 设定表格语言
              plugins:['chart'],
              data:[
                    {
                        "name": "Cell", //工作表名称
                        "color": "", //工作表颜色
                        "index": 0, //工作表索引
                        "status": 1, //激活状态
                        "order": 0, //工作表的下标
                        "hide": 0,//是否隐藏
                        "row": 36, //行数
                        "column": 18, //列数
                        "defaultRowHeight": 19, //自定义行高
                        "defaultColWidth": 73, //自定义列宽
                        "celldata": [], //初始化使用的单元格数据
                        "config": {
                            "merge":{}, //合并单元格
                            "rowlen":{}, //表格行高
                            "columnlen":{}, //表格列宽
                            "rowhidden":{}, //隐藏行
                            "colhidden":{}, //隐藏列
                            "borderInfo":{}, //边框
                            "authority":{}, //工作表保护
                            
                        },
                        "scrollLeft": 0, //左右滚动条位置
                        "scrollTop": 315, //上下滚动条位置
                        "luckysheet_select_save": [], //选中的区域
                        "calcChain": [],//公式链
                        "isPivotTable":false,//是否数据透视表
                        "pivotTable":{},//数据透视表设置
                        "filter_select": {},//筛选范围
                        "filter": null,//筛选配置
                        "luckysheet_alternateformat_save": [], //交替颜色
                        "luckysheet_alternateformat_save_modelCustom": [], //自定义交替颜色	
                        "luckysheet_conditionformat_save": {},//条件格式
                        "frozen": {}, //冻结行列配置
                        "chart": [], //图表配置
                        "zoomRatio":1, // 缩放比例
                        "image":[], //图片
                        "showGridLines": 1, //是否显示网格线
                        "dataVerification":{} //数据验证配置
                    },
                    {
                        "name": "Sheet2",
                        "color": "",
                        "index": 1,
                        "status": 0,
                        "order": 1,
                        "celldata": [],
                        "config": {}
                    },
                    {
                        "name": "Sheet3",
                        "color": "",
                        "index": 2,
                        "status": 0,
                        "order": 2,
                        "celldata": [],
                        "config": {},
                    }
                ]
            });
          });
      
    // });
  },
  methods:{
	isWeChatBrowser() {
		const userAgent = navigator.userAgent.toLowerCase();
		if (userAgent.match(/MicroMessenger/i) == 'micromessenger') {
			this.isflag = true;
			this.isMaskShow = false;
		}
	  },
	  
	  saveFile(){
		 const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }) // 创建Blob对象
		  const url = URL.createObjectURL(blob) // 创建可下载的链接
		  const a = document.createElement('a') // 创建a标签
		  a.href = url // 设置链接
		  a.download = filename // 设置文件名
		  a.click() // 模拟用户点击下载
		  URL.revokeObjectURL(url) // 释放链接  
	  },
    uploadExcel(evt){
        const files = evt.target.files;
        if(files==null || files.length==0){
            alert("No files wait for import");
            return;
        }   
        let name = files[0].name;
		let excel = name.split('.');
		this.excelname = excel[0];
        let suffixArr = name.split("."), suffix = suffixArr[suffixArr.length-1]; 
        if(suffix!="xlsx"){
            alert("Currently only supports the import of xlsx files");
            return;
        }
		
		this.selected ='';
        LuckyExcel.transformExcelToLucky(files[0], function(exportJson, luckysheetfile){
            if(exportJson.sheets==null || exportJson.sheets.length==0){
                alert("Failed to read the content of the excel file, currently does not support xls files!");
                return;
            }
            window.luckysheet.destroy(); 
            window.luckysheet.create({
                container: 'luckysheet', //luckysheet is the container id
                showinfobar:false,
                data:exportJson.sheets,
                title:exportJson.info.name,
                userInfo:exportJson.info.name.creator
            });
        });
    },
    selectExcel(evt){
        const value = this.selected;
        const name = evt.target.options[evt.target.selectedIndex].innerText;
        if(value==""){
            return;
        }
		document.getElementById('fileupload').value = '';
        this.isMaskShow = true;
        LuckyExcel.transformExcelToLuckyByUrl(value, name, (exportJson, luckysheetfile) => {
            if(exportJson.sheets==null || exportJson.sheets.length==0){
                alert("Failed to read the content of the excel file, currently does not support xls files!");
                return;
            }
            this.isMaskShow = false;
            window.luckysheet.destroy();
            window.luckysheet.create({
                container: 'luckysheet', //luckysheet is the container id
                showinfobar:false,
                data:exportJson.sheets,
                title:exportJson.info.name,
                userInfo:exportJson.info.name.creator
            });
        });
    },
    downloadExcel(){ 
		  // console.log(luckysheet.getluckysheetfile())
          const value = this.selected;
          if(value.length==0){
             if(this.excelname!=''){
				let suffixArr = this.excelname.split('.');
				exportExcel(luckysheet.getluckysheetfile(), suffixArr[0])
             }else{
             	 exportExcel(luckysheet.getluckysheetfile(), '蓝牙卡尺')
             }   
          }else{
			  var elemIF = document.getElementById("Lucky-download-frame");
			  if(elemIF==null){
			      elemIF = document.createElement("iframe");
			      elemIF.style.display = "none";
			      elemIF.id = "Lucky-download-frame";
			      document.body.appendChild(elemIF);
			  }
			  elemIF.src = value;
		  }
          
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.pop {
	 position: fixed;
	  top: 0;
	  left: 0;
	  right: 0;
	  background-color: rgba(0,0,0,.7);
	  text-align: center;
	  width: 100%;
	  height: 100%; 
	  z-index: 9999;
}
</style>
